@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';

.main-opinion-stories {
	@media (min-width: $desktop-breakpoint) {
		// MainOpinionStories component's full width grid need to have the same margins as layout-desktop__grid-container
		// otherwise when the grid width is devided by the same number, the column width is different
		.grid--12-columns {
			margin-left: -$horizontal-spacing;
			margin-right: -$horizontal-spacing;
		}
	}

	.main-opinion-stories__secondary-story {
		padding: 0 $horizontal-spacing;
		@media (min-width: $desktop-breakpoint) {
			padding-left: $horizontal-spacing;
			padding-right: $horizontal-spacing;
		}
	}
	.main-opinion-stories__primary-story,
	&.main-opinion-stories--only-one  {
		.primary-opinion-story__teaser {
			padding: 0 $horizontal-spacing;
			@media (min-width: $desktop-breakpoint) {
				padding: 0;
			}
		}
	}

	.slice-heading {
		display: block;
		margin-top: -20px;
	}

	.separator--solid {
		margin: $vertical-spacing 0;
	}

	@media (min-width: $desktop-breakpoint) {
		.separator--solid {
			display: none;
		}
	}
}

// Featured theme custom styles
.main-opinion-stories--theme-featured {
	
	.headline .headline-indicator {
		// force the same icon size (icon--scale-5) for all stories
		> span {
			height: 19px;

			&:before {
				width:42px;
				height:42px;
				margin-top: -12px;
			}
		}
	}

	// Main Story
	.main-opinion-stories__primary-story {
		@media (min-width: $desktop-breakpoint) {
			margin-left: $horizontal-spacing;
		}
	}

	.main-opinion-stories__primary-story,
	&.main-opinion-stories--only-one {
		background-color: oColorsByName('wheat');
		padding-bottom: $vertical-spacing * 2;

		// Display the indicator separated from the title
		.headline .headline-indicator .icon {
			padding: 10px;
			display: block;
		}

		@media (min-width: $desktop-breakpoint) {
			padding-bottom: 0;
		}
	}

	// Second Story
	.main-opinion-stories__secondary-story {
		padding-bottom: $vertical-spacing * 2;
		padding-top: $vertical-spacing * 3; // set padding because separator is hidden

		.separator--solid {
			display: none;
		}


		@media (min-width: $desktop-breakpoint) {
			padding-bottom: 0;
			padding-top: 0;
			padding-right: $horizontal-spacing;
			text-align: center;
			justify-content: center;
			
			.grid--fullHeight {
				align-content: center;
			}
			.metadata__opinion {
				justify-content: center;
			}

			// Display the indicator separated from the title (only for desktop)
			.headline .headline-indicator .icon {
				padding: 20px;
				display: block;
			}
		}
	}
}

.main-opinion-stories__primary-story {
	@include leftColumnSpan($left-column-span: 9);
	height: 100%;
	@media (min-width: $desktop-breakpoint) {
		margin-right: $horizontal-spacing;

		.main-opinion-stories--only-one & {
			margin-right: 0;
		}
	}
}

.main-opinion-stories__secondary-story {
	@include rightColumnSpan($total-grid-columns: 12, $left-column-span: 9);
	height: 100%;
	@media (min-width: $desktop-breakpoint) {
		border-left: solid oColorsByName('black-10') 1px;
		padding-left: $horizontal-spacing;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:279");