@use "sass:meta" as ---sy8r8nhqffb;.story-group-stacked {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	padding-bottom: $vertical-spacing * 2;

	.grid {
		align-content: end;
	}

	@media (min-width: $desktop-breakpoint) {
		padding-bottom: 0;
	}

	.story-group__article {
		padding: 0;
	}

	.grid__story-group-wrapper--secondary {
		padding-top: $vertical-spacing * 2;
		padding-left: $horizontal-spacing;
		padding-right: $horizontal-spacing;

		@media (min-width: $desktop-breakpoint) {
			padding-left: 0;
			padding-right: 0;
			grid-gap: inherit;
		}
	}

	&--hide-image .primary-story__image {
		display: none;
	}

	// if set, 'show-splash-asset', should override 'hide-image'
	// and display the asset (image or clip) only for mobile viewport
	&--show-splash-asset .primary-story__image {
		display: block;

		@media (min-width: $desktop-breakpoint) {
			display: none !important;
		}
	}
}

.story-group-stacked--reduced-width .grid__story-group-wrapper--secondary {
	.stacked-secondary-story--span-1,
	.stacked-secondary-story--span-2,
	.stacked-secondary-story--span-3,
	.stacked-secondary-story--span-4 {
		@media (min-width: $desktop-breakpoint) {
			&:not(.stacked-secondary-story__force-grid-on-desktop, .separator--solid) {
				width: 70%;
			}
		}
	}
}

.story-group-stacked__primary-story {
	height: 100%;
}

// Remove normal story group articles margin
.story-group-stacked .story-group__article {
	margin-bottom: 0 !important;
}

.stacked-secondary-story {
	.metadata:not(.metadata__live-blog) {
		display: none;

		@media (min-width: $desktop-breakpoint) {
			display: flex;
		}
	}

	@media (max-width: ($desktop-breakpoint - 1)) {
		.metadata__live-blog {
			margin-top: $vertical-spacing;
		}
		.grid {
			grid-gap: 0;
		}
	}
}

.story-group-named-slice {
	// We want to padding top only for:
	// - Triple slice (50:50 uses story-group-stacked too, but it's deprecated)
	// - Small screens
	// - When no splash asset displayed
	.story-group-stacked:not(.story-group-stacked--show-splash-asset) {
		padding-top: 2 * $vertical-spacing;
		@media (min-width: $desktop-breakpoint) {
			padding-top: 0;
		}
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:300");