@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';

@include oLabels($opts: ('indicators': ('status': ('live'), 'inverse': true)));

.metadata__live-blog-label {
	background-color: oColorsByName('crimson');
	margin-right: 8px;
	padding: 1px 8px 3px;
	font-weight: 700;
	display: inline-block;
}

/* Opinion */

.metadata__opinion {
	display: flex;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid oColorsMix(black, paper, 20);
		background-color: oColorsMix(black, paper, 10);
	}
}

.author-opinion-details {
	margin-left: 0.725rem;
	display: flex;
	align-items: center;
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:272");