@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';

.featured-story {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 100%;
	box-sizing: border-box;
	padding-top: 0;
	margin: 0;
}

.featured-story--theme-tinted {
	background-color: oColorsByName('wheat');

	.featured-story-content {
		justify-content: center;
	}
}

.featured-story > * {
	margin-bottom: $vertical-spacing;
}

.featured-story-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	padding: 0 $vertical-spacing;
	width: 100%;
	box-sizing: border-box;

	.headline {
		padding: 0 $horizontal-spacing;
		width: 100%;
		box-sizing: border-box;
	}
}

.featured-story__image {
	width: 100%;
}

.story-group__article--featured {
	padding-bottom: $vertical-spacing;
}

.featured-story--hide-image-on-mobile {
	.featured-story__image {
		display: none;
		
		@media (min-width: $desktop-breakpoint) {
			display: block;
		}
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:281");