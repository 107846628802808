@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';

$breakpoint-hide-side-margins: $content-column-max-width + ($feedback-tag-width * 2);

.slice {
	margin-bottom: 1.5em;
	display: grid;
	grid-template-areas: '. Content .';
	grid-template-columns: 1fr $content-column-max-width 1fr;

	@supports not (display: -ms-grid) {
		grid-template-columns: 1fr minmax(auto, $content-column-max-width) 1fr;
	}
}

.slice__content-withoutColour {
	grid-area: Content;
}

.slice--full-bleed {
	background-color: oColorsByName('wheat');
	padding-bottom: $section-vertical-spacing !important; // override theme padding-bottom

	@media (min-width: $desktop-breakpoint) {
		padding-top: $section-vertical-spacing;

		&.slice--full-bleed--less-top-padding {
			padding-top: 10px;
		}
	}

	.slice__content {
		background-color: oColorsByName('wheat');
	}
}

.slice--below-slice-associated {
	&.slice--dark,
	&.slice--impact,
	&.slice--full-bleed {
		margin-bottom: 0;
	}
}

.slice--impact,
.slice--dark {
	padding-bottom: $vertical-spacing * 2;

	@media (min-width: $desktop-breakpoint) {
		.slice-heading {
			padding-bottom: $vertical-spacing * 2;
		}
	}
}

.slice--impact {
	background-color: oColorsByName('wheat');

	.slice__content {
		background-color: oColorsByName('wheat');
	}
}

.slice--dark {
	$slice-dark-background-color: 'slate';
	background-color: oColorsByName($slice-dark-background-color);

	.slice__content {
		background-color: oColorsByName($slice-dark-background-color);
		@include dark-mode-icon;
		@include dark-mode-text($slice-dark-background-color);
		// when change dark-mode-text mixin above this visited style may need to update as well
		.headline :visited .text {
			color: oColorsMix(white, $slice-dark-background-color, 85);
		}

		@include dark-mode-timestamp;

		.breaking-story-group--secondary-stories {
			.stacked-secondary-story--span-1,
			.stacked-secondary-story--span-2,
			.stacked-secondary-story--span-3,
			.stacked-secondary-story--span-4,
			.separator--solid {
				border-color: oColorsMix(black, white, 20);
			}
		}
	}
}

.slice__content {
	grid-area: Content;
	background-color: oColorsByName('paper');
	border-radius: 4px;
	padding-left: $horizontal-spacing;
	padding-right: $horizontal-spacing;

	@media (min-width: $desktop-breakpoint) {
		padding-left: $horizontal-spacing * 2;
		padding-right: $horizontal-spacing * 2;
	}

	@media (min-width: $breakpoint-hide-side-margins) {
		padding-left: 0;
		padding-right: 0;
	}
}

.slice__no-title > .slice__content > .separator--solid,
.slice__no-title > .slice__content > .separator--dotted {
	margin-bottom: 1.5rem;
}

.slice.top-stories-slice .story-group__article--lead,
.breaking-story-group--primary-stories {
	.metadata {
		padding: 0 $horizontal-spacing;

		@media (min-width: $desktop-breakpoint) {
			padding: 0;
		}
	}
}

// Add horizontal padding for on "sections" on mobile
.slice:not(.top-stories-slice) .story-group {
	padding: 0 $horizontal-spacing;

	@media (min-width: $desktop-breakpoint) {
		padding: inherit;
	}
}

.slice--has-sub-navigation {
	header.slice-heading {
		padding: 0;
		padding-top: 0.5rem;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:274");