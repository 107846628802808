@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';

html.no-js .interactive-graphic-slice {
	display: none;
}

.interactive-graphic-slice--top-slice {
	padding-top: $vertical-spacing;

	@media (min-width: $desktop-breakpoint) {
		padding-top: $section-vertical-spacing;
	}
}

.interactive-graphic-slice .slice--impact .separator--solid,
.interactive-graphic-slice .slice--full-bleed .separator--solid {
	border-color: oColorsByName('black-30');
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:319");