@use "sass:meta" as ---sy8r8nhqffb;.interactive-graphic {
	display: none;

	iframe {
		border: 0;
		width: 100%;
		height: 0;
	}
}

.interactive-graphic.loadingComplete {
	display: block;
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:290");