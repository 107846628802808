@use "sass:meta" as ---sy8r8nhqffb;.slice-heading {
	padding: 0.5rem 1rem 1rem 1rem;
}

.slice-heading__title {
	margin: 0;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 2px;

  @media (min-width: $desktop-breakpoint) {
		&.spacing {
			padding-bottom: 20px;
		}
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:273");