@use "sass:meta" as ---sy8r8nhqffb;.promo__links {
	display: flex;
	align-self: flex-end;
	padding-top: $vertical-spacing;

	@media (min-width: $desktop-breakpoint) {
		width: 90%;
	}
}

.promo__links-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: right;
	padding-right: 20px;
	width: 100%;
}

.promo__links-image {
	min-width: 100px;
	max-width: 100px;
}

.promo__links--spacing {
	display: block;
	margin-top: 12px;

	@media (min-width: $desktop-breakpoint) {
		padding-left: 20%;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:286");