@use "sass:meta" as ---sy8r8nhqffb;$ad-medium-breakpoint: 760px;

/*
 * DO NOT REMOVE!
 *
 * These rules *must* be part of next-home-page's CSS payload to minimise CLS
 *
 * PG also ships its own rules, but these are only added on DOMReady.
 * The delay would introduce a noticeable rendering jump
 */
.above-header-advert {
	display: none;
	padding: 0;

	@media (min-width: $ad-medium-breakpoint) {
		display: block;
	}
}

.high-impact-ad[data-o-ads-loaded='OneByOne'] {
	display: none;
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:142");