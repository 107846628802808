@use "sass:meta" as ---sy8r8nhqffb;.primary-story__image {
	grid-column-start: 2;
	order: -1;

	@media (min-width: $desktop-breakpoint) {
		order: unset;
	}
}

@for $i from 2 through 4 {
	.primary-story__image--#{$i}-cols {
		grid-column: span #{$i - 1};
	}
}

.primary-story__image--1-cols {
	grid-column-start: 1;
}

.primary-story__teaser .headline {
	margin-bottom: $vertical-spacing;
}

.primary-story__teaser .primary-story__image--1-cols {
	/*for images in extra small layout only*/
	margin-bottom: 0.75rem;
}


;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:275");