@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/cp-content-pipeline-ui/src/components/content-tree/Clip/client/main';

.cp-clip {
	text-align: start;
	width: 100%;
	height: 100%;

	.cp-clip__video {
		width: 100%;
		height: 100%;
	}

	.cp-clip__video-container {
		width: 100%;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:291");