@use "sass:meta" as ---sy8r8nhqffb;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

div[data-trackable='list:latest-from-monetary-policy-radar'] .story-collection {
	padding: 0 1rem;

	> *:not(:last-child) {
		border-bottom: 1px solid oColorsMix('white', 'slate', 20);
	}

	@media (min-width: $desktop-breakpoint) {
		padding: 0;
		> *:not(:last-child) {
			border-right: 1px solid oColorsMix('white', 'slate', 20);
			border-bottom: none;
		}
	}

	// remove padding from heading as the text is so long
	// we don't want it to wrap too soon on mobile
	.slice-heading {
		padding-left: 0;
		padding-right: 0;
	}
}

.monetary-policy-radar__content {
	padding-right: $horizontal-spacing;
}

.monetary-policy-radar__title {
	margin-bottom: $vertical-spacing;
}

.monetary-policy-radar__headline {
	margin-bottom: $vertical-spacing;
}

.monetary-policy-radar__timestamp {
	margin-bottom: $vertical-spacing;

	// don't apply colour when the timestamp is 'new'
	// in this case use the default white colour
	.timestamp:not(:has(.timestamp-prefix)) .o-date {
		color: oColorsMix('white', 'slate', 60);
	}

	@media (min-width: $desktop-breakpoint) {
		margin-bottom: 0;
	}
}

div[data-trackable='list:latest-from-monetary-policy-radar'] .slice__content::after {
	content: 'You can see this section because you have access to Monetary Policy Radar.';
	@include oTypographySans($scale: -1);
	margin-top: $vertical-spacing * 2;
	margin-bottom: -1rem;
	color: oColorsMix('white', 'slate', 80);
	display: block;
	font-style: normal;
	text-align: left;

	@media (min-width: $desktop-breakpoint) {
		text-align: center;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:289");