@use "sass:meta" as ---sy8r8nhqffb;$o-spacing-relative-units: true;
$alignments: end start;
@import 'o-spacing/main';

.grid {
	display: grid;
}

.grid--fullHeight {
	height: 100%;
}

// Used by 4 col grids inside storygroups
.grid--1-columns {
	grid-template-columns: 1fr;

	:nth-child(2) {
		-ms-grid-row: 2;
	}

	:nth-child(3) {
		-ms-grid-row: 3;
	}
}

@for $i from 2 through 12 {
	.grid--#{$i}-columns {
		grid-template-columns: 1fr;

		@media (min-width: $desktop-breakpoint) {
			grid-template-columns: repeat($i, 1fr);
		}
	}

	.grid-item--span-#{$i} {
		grid-column: span 1;

		@media (min-width: $desktop-breakpoint) {
			grid-column: span $i;
		}
	}

	.grid-item--row-span-#{$i} {
		grid-row: span $i;
	}
}

@each $space in $o-spacing-names {
	.grid--#{$space}-spacing {
		grid-gap: oSpacingByName($space);
	}
}

@each $align in $alignments {
	.grid-item--align-self-#{$align} {
		align-self: $align;
	}
}

;@include ---sy8r8nhqffb.load-css("sass-embedded-legacy-load-done:316");